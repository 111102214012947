window.addEventListener("load", function() {
  let mapContainer = document.getElementById("map");
  if (mapContainer) {
    // map
    var locations = [
      {
        title: "Minya Governorate",
        description: "Minya test information",
        location: {
          lat: 28.0871,
          lng: 30.7618
        }
      },
      {
        title: "Cairo Governorate",
        description: "Cairo test information",
        location: {
          lat: 30.0444,
          lng: 31.2357
        }
      }
    ];

    var markerArray = []; //array of markers

    //function >> when google map failed to load
    function googleError() {
      alert("failed to load the map ..try again");
    }

    window.googleError = googleError;

    //function to animate the marker
    function toggleBounce(marker) {
      "use strict";
      if (marker.getAnimation() !== null) {
        marker.setAnimation(null);
      } else {
        marker.setAnimation(google.maps.Animation.BOUNCE);
      }
    }

    function initMap() {
      //init map
      "use strict";
      var map = new google.maps.Map(mapContainer, {
        //options of the map
        zoom: 7,
        center: {
          //center of the map
          lat: 29.1783,
          lng: 31.1859
        }
      });

      var largeInfoWindow = new google.maps.InfoWindow();
      //adding marker to each location
      for (let i = 0; i < locations.length; i++) {
        var marker = new google.maps.Marker({
          position: locations[i].location,
          map: map,
          title: locations[i].title,
          animation: google.maps.Animation.DROP,
          icon: {
            // url: "https://getdrawings.com/free-icon/map-marker-icon-png-52.png",
            scaledSize: new google.maps.Size(40, 40)
          }
        });
        marker.addListener("click", function() {
          populateInfoWindow(this, largeInfoWindow);
        });

        function populateInfoWindow(marker, infoWindow) {
          "use strict";
          infoWindow.marker = marker;
          let content = `
                        <h6 class="m-0">${marker.title}</h6>
                        <small class="d-block mt-1 mb-2">${locations[i].description}</small>
                    `;

          infoWindow.setContent(content);
          infoWindow.open(map, marker);

          //onclick set toggleBounce function to the marker
          toggleBounce(marker);
          setTimeout(function() {
            //after 700 ms remove the animation
            marker.setAnimation(null);
          }, 700);
        }

        markerArray.push(marker);
      }
    }

    initMap();
  }
});
