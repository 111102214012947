export default {
  required: {
    empty: "من فضلك يجب ادخال هذا الحقل"
  },
  email: {
    incorrect: "من فضلك يجب ادخال بريد الكتروني صحيح"
  },
  phone: {
    incorrect: "من فضلك يجب ادخال رقم هاتف صحيح"
  }
};
