import "./pages";
import "./form-validation";
require("fslightbox");

window.addEventListener("load", function() {
  // remove loading spinner
  document.getElementById("loading-spinner").remove();
  // scroll top btn
  let scrollTopBtn = document.getElementById("scroll-top-btn");
  scrollTopBtn.addEventListener("click", () => {
    this.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  // show onscroll
  window.addEventListener("scroll", function() {
    scrollTopBtn.classList.toggle("show", this.scrollY >= 200);
  });

  // toggle header nav
  let toggleNavIcon = document.getElementById("toggle-nav-icon");
  toggleNavIcon.addEventListener("click", function() {
    document.getElementById("header-nav").classList.toggle("show");
    this.classList.toggle("open");
  });
});
