export default {
  required: {
    empty: "This Field Is Required"
  },
  email: {
    incorrect: "Please Enter a Valid Email Address"
  },
  phone: {
    incorrect: "Please Enter a Valid Phone Number"
  }
};
