let Validator = require("../vendors/js-form-validator.min");
import arValidationMessages from "./messages/ar";
import enValidationMessages from "./messages/en";
import { RTL_MODE } from "../helpers";

window.addEventListener("DOMContentLoaded", function() {
  let locale = RTL_MODE ? "ar" : "en";
  let options = {
    errorClassName: "error-feedback",
    locale,
    // custom rules
    rules: {},
    messages: {
      en: enValidationMessages,
      ar: arValidationMessages
    }
  };

  // validate form
  let validatedForm = document.getElementById("validated-form");
  validatedForm &&
    new Validator(
      validatedForm,
      function(err, res) {
        // if form is valid
        if (res) {
          validatedForm.submit();
        }
        return false;
      },
      options
    );
});
