import Glide from "@glidejs/glide";
import { RTL_MODE } from "../helpers";

window.addEventListener("load", function() {
  // ************************** home slider *************************
  function addSliderAnimation() {
    let activeSlide = homeSliderElement.querySelector(".glide__slide--active");
    let activeHeading = activeSlide.querySelector("h2");
    let activeText = activeSlide.querySelector("p");
    let activeBtn = activeSlide.querySelector(".btn");
    activeHeading && activeHeading.classList.add("animate__fadeInUp");
    setTimeout(
      () => activeText && activeText.classList.add("animate__fadeInUp"),
      300
    );
    setTimeout(
      () => activeBtn && activeBtn.classList.add("animate__fadeInUp"),
      600
    );
  }

  let homeSliderElement = document.getElementById("home-slider");
  if (homeSliderElement) {
    let homeSlider = new Glide(homeSliderElement, {
      type: "carousel",
      autoplay: 7000,
      gap: 0,
      animationDuration: 400
    }).mount();

    addSliderAnimation();
    homeSlider.on("move", function() {
      let activeSlide = homeSliderElement.querySelector(
        ".glide__slide--active"
      );
      let activeHeading = activeSlide.querySelector("h2");
      let activeText = activeSlide.querySelector("p");
      let activeBtn = activeSlide.querySelector(".btn");

      setTimeout(() => {
        activeHeading && activeHeading.classList.remove("animate__fadeInUp");
        activeText && activeText.classList.remove("animate__fadeInUp");
        activeBtn && activeBtn.classList.remove("animate__fadeInUp");
      }, 400);
    });

    homeSlider.on(["move.after"], function() {
      addSliderAnimation();
    });
  }

  // to hide slider arrows (if first slide or last slide is active)
  var ArrowDisabler = function(Glide, Components) {
    return {
      mount() {
        // Only in effect when rewinding is disabled
        if (Glide.settings.rewind) {
          return;
        }

        Glide.on(["mount.after", "run"], () => {
          // Filter out arrows_control
          for (let controlItem of Components.Controls.items) {
            if (controlItem.className !== "glide__arrows") {
              continue;
            }

            // Set left arrow state
            var left = controlItem.querySelector(
              `.glide__arrow--${RTL_MODE ? "right" : "left"}`
            );
            if (left) {
              if (
                Glide.index === 0 ||
                Components.Sizes.length < Glide.settings.perView
              ) {
                left.classList.add("disabled");
              } else {
                left.classList.remove("disabled");
              }
            }

            // Set right arrow state
            var right = controlItem.querySelector(
              `.glide__arrow--${RTL_MODE ? "left" : "right"}`
            );
            if (right) {
              if (
                Glide.index ===
                  Components.Sizes.length - Glide.settings.perView ||
                Components.Sizes.length < Glide.settings.perView
              ) {
                right.classList.add("disabled");
              } else {
                right.classList.remove("disabled");
              }
            }
          }
        });
      }
    };
  };

  // ************************** similar packages slider *************************
  let similarPackages = document.getElementById("similar-packages-slider");
  similarPackages &&
    new Glide(similarPackages, {
      type: "slider",
      perView: 3,
      bound: true,
      rewind: false,
      focusAt: "0",
      gap: 18,
      direction: RTL_MODE ? "rtl" : "ltr",
      breakpoints: {
        768: {
          perView: 1
        },
        900: {
          perView: 2
        },
        1100: {
          perView: 2
        }
      }
    }).mount({
      ArrowDisabler
    });
});
